import React from 'react'
import { Link } from 'react-router-dom'

export default function Header() {
	return (
		<section className="text-gray-600 body-font min-h-[90vh]">
			<div className="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
				<img
					className="lg:w-2/6 md:w-3/6 w-5/6 mb-10 object-cover object-center"
					alt="hero"
					src={require('../../../assets/images/icon.png')}
				/>
				<div className="text-center lg:w-2/5 w-full">
					<h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-blue-500">Pubhelper</h1>
					<p className="mb-8 leading-relaxed">
						Our vision is to provide aspiring researchers with skills and knowledge required to take on any future
						project with confidence.
					</p>
					<div className="flex justify-center">
						<Link
							to="/get-a-quote"
							className="inline-flex text-white bg-blue-500 border-0 py-2 px-6 focus:outline-none hover:bg-blue-600 rounded text-lg"
						>
							Get a quote
						</Link>
						<a
							href="#services"
							className="ml-4 inline-flex text-gray-700 bg-gray-100 border-0 py-2 px-6 focus:outline-none hover:bg-gray-200 rounded text-lg"
						>
							Our Service
						</a>
					</div>
				</div>
			</div>
		</section>
	)
}
