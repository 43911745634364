import React from 'react'
import { Link } from 'react-router-dom'
import useOutsideClick from '../../../hooks/useOutsideClick'

export default function ProfileDropdown({ logout }) {
	const [dropDownOpen, setDropDownOpen] = React.useState(false)
	const { innerBorderRef: dropdownRef } = useOutsideClick(() => setDropDownOpen(false))

	return (
		<div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
			<div className="ml-3 relative">
				<div>
					<button
						type="button"
						className="text-white flex text-sm rounded-full focus:outline-none"
						id="user-menu-button"
						aria-expanded="false"
						aria-haspopup="true"
						onClick={() => setDropDownOpen(prev => !prev)}
						ref={dropdownRef}
					>
						<span className="sr-only">Open user menu</span>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="h-8 w-8"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
							strokeWidth={2}
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
							/>
						</svg>{' '}
					</button>
				</div>
				<div
					className={
						'origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none' +
						(!dropDownOpen && ' hidden')
					}
					role="menu"
					aria-orientation="vertical"
					aria-labelledby="user-menu-button"
					tabIndex="-1"
				>
					<Link
						to="/dashboard"
						className="block hover:bg-gray-100 px-4 py-2 text-sm text-gray-700"
						role="menuitem"
						tabIndex="-1"
						id="user-menu-item-0"
					>
						Dashboard
					</Link>
					<span
						onClick={() => logout()}
						className="block hover:bg-gray-100 px-4 cursor-pointer py-2 text-sm text-gray-700"
						role="menuitem"
						tabIndex="-1"
						id="user-menu-item-2"
					>
						Sign out
					</span>
				</div>
			</div>
		</div>
	)
}
