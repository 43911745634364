import { useLocation, Navigate, Outlet } from 'react-router-dom'
import useAuth from '../hooks/useAuth'

const PrivateRoute = ({ allowedRoles }) => {
	const { getUser } = useAuth()
	const location = useLocation()

	const user = getUser()

	return allowedRoles?.includes(user?.role) ? (
		<Outlet />
	) : user ? (
		<Navigate to="/unauthorized" state={{ from: location }} replace />
	) : (
		<Navigate to="/login" state={{ from: location }} replace />
	)
}

export default PrivateRoute
