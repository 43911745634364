import React from 'react'
import { Link } from 'react-router-dom'

export default function ErrorComponent({ message }) {
	return (
		<div className="max-w-7xl mx-auto flex flex-col justify-center items-center h-[90vh]">
			<img
				src={require('../../assets/images/error.png')}
				className="lg:w-2/6 md:w-3/6 w-5/6 mb-10 object-cover object-center"
				alt="Error"
			/>
			<h3 className="text-2xl my-5 text-center text-gray-400">{message}</h3>
			<Link
				to="/dashboard"
				className="mt-4 mb-3 px-5 bg-blue-500 hover:bg-blue-600 text-white py-2 rounded-md transition duration-100"
			>
				{' '}
				Go Back to dashboard
			</Link>
		</div>
	)
}
