import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'

import { useParams } from 'react-router-dom'
import Badge from '../../../components/Badge'
import ErrorComponent from '../../../components/Error'
import Loader from '../../../components/Loaders'
import { publicApi } from '../../../helpers/api'
import useAuth from '../../../hooks/useAuth'
import Input from '../../../components/Form/Input'

export default function SingleProject() {
	let { id } = useParams()
	const { authorization } = useAuth()
	const [project, setProject] = useState({})
	const [error, setError] = useState('')
	const [loading, setLoading] = useState({})

	const [message, setMessage] = useState('')

	const handleStatusUpdate = status => {
		setLoading(true)
		const data = new FormData()
		data.append('authorization', authorization())
		data.append('status', status)

		publicApi
			.post('/projects/update/status/' + id, data)
			.then(
				({ data }) => {
					setMessage(data.message)
					setProject(prev => ({ ...prev, project_status: status }))
				},
				({ response }) => {
					setError(response.data.message)
				}
			)
			.finally(() => {
				setLoading(false)
				setTimeout(() => {
					setMessage('')
				}, 3000)
			})
	}
	const handlePaymentUpdate = payment => {
		setLoading(true)
		const data = new FormData()
		data.append('authorization', authorization())
		data.append('payment', payment)

		publicApi
			.post('/projects/update/payment/' + id, data)
			.then(
				({ data }) => {
					setMessage(data.message)
					setProject(prev => ({ ...prev, project_payment: payment }))
				},
				({ response }) => {
					setError(response.data.message)
				}
			)
			.finally(() => {
				setLoading(false)
				setTimeout(() => {
					setMessage('')
				}, 3000)
			})
	}

	useEffect(() => {
		const auth = new FormData()
		auth.append('authorization', authorization())

		publicApi
			.post('/projects/show/' + id, auth)
			.then(
				({ data }) => {
					setProject(data)
					console.log(data)
				},
				({ response }) => {
					setError(response.data.message)
				}
			)
			.finally(() => setLoading(false))
	}, [id])

	const renderStatus = status => {
		if (status === 'pending') {
			return <Badge color="bg-yellow-500">Pending</Badge>
		}
		if (status === 'started') {
			return <Badge color="bg-blue-500">Started</Badge>
		}
		if (status === 'completed') {
			return <Badge color="bg-emerald-500">completed</Badge>
		}
		if (status === 'rejected') {
			return <Badge color="bg-rose-500">Rejected</Badge>
		}
	}

	if (loading) return <Loader />
	if (error) return <ErrorComponent message={error} />
	return (
		<div className="max-w-7xl mx-auto my-10 px-2">
			{message && (
				<div className="bg-emerald-100 rounded-lg py-5 px-6 mb-4 text-base text-emerald-700" role="alert">
					{message}
				</div>
			)}
			<div className="p-4 md:p-6 rounded-lg shadow-lg bg-gray-100">
				<div className="mb-3 pb-3 border-b-4">
					<div className="flex md:flex-row flex-col">
						<div className="w-full md:w-1/3">
							<span className="font-semibold">Status: </span> {renderStatus(project.project_status)}
						</div>
						<div className="w-full md:w-1/3">
							<span className="font-semibold">Created: </span>{' '}
							{format(new Date(project.created_at), 'dd MMM, yyyy hh:ss a')}
						</div>
						<div className="w-full md:w-1/3">
							<span className="font-semibold">Updated: </span>{' '}
							{format(new Date(project.updated_at), 'dd MMM, yyyy hh:ss a')}
						</div>
					</div>
				</div>

				<h2 className="text-xl font-semibold text-blue-500 mb-5">{project.project_name}</h2>
				<h3 className="font-semibold text-lg text-gray-500">Customer Details:</h3>
				<div className="md:flex gap-5 text-gray-500">
					<div className="w-full md:w-1/3">
						<p>
							<span className="font-semibold">Name: </span>
							{project.customer_name}
						</p>
					</div>
					<div className="w-full md:w-1/3">
						<p>
							<span className="font-semibold">Email: </span>
							{project.customer_email}
						</p>
					</div>
					<div className="w-full md:w-1/3">
						<p>
							<span className="font-semibold">Phone: </span>
							{project.customer_phone}
						</p>
					</div>
				</div>
				<h3 className="text-lg py-3 my-3 border-t-4 border-b-4 text-gray-500">
					<span className="font-semibold ">Service Requested: </span>
					{project.project_service}
				</h3>
				<h3 className="text-lg py-3 my-3 border-b-4 text-gray-500">
					<span className="font-semibold">Project File: </span>
					<a
						target={'_blank'}
						rel="noopener noreferrer"
						href={`//api.pubhelper.com/files/projects/${project.project_file}`}
					>
						{project.project_file}
					</a>
				</h3>

				<h3 className="text-lg py-3 my-3 font-semibold text-gray-500">Additional Comments</h3>
				<p>{project.comments}</p>
			</div>
			<div className="flex md:flex-row flex-col gap-5 my-5">
				<div className="w-full md:w-1/2 mt-5">
					<label className="block font-semibold text-gray-500 text-md mb-2" htmlFor="status">
						Change status
					</label>
					<select
						className="px-4 transition-all focus:border-blue-500 w-full border-2 py-2 rounded-md text-sm outline-none"
						onChange={({ target }) => handleStatusUpdate(target.value)}
						id="status"
						value={project.project_status}
					>
						<option value="pending">Pending</option>
						<option value="rejected">Rejected</option>
						<option value="completed">Completed</option>
						<option value="started">Started</option>
					</select>
				</div>
				<div className="w-full md:w-1/2">
					<Input
						label={'Payment Decided'}
						onBlur={({ target }) => handlePaymentUpdate(target.value)}
						id="project_payment"
						defaultValue={project.project_payment || ''}
					/>
				</div>
			</div>
		</div>
	)
}
