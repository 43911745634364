import axios from 'axios'
const BASE_URL = 'https://api.pubhelper.com'

const publicApi = axios.create({
	baseURL: BASE_URL,
	headers: {
		accept: 'application/json'
	}
})

const api = axios.create({
	baseURL: BASE_URL,
	headers: {
		accept: '*/*'
	}
})

export { api, publicApi }
