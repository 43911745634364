import React from 'react'
import Header from './components/Header'
import Services from './components/Services'

export default function Home() {
	return (
		<div>
			<Header />
			<Services />
			<div className="container px-5 py-24 mx-auto">
				<div className="flex md:flex-row gap-5 flex-col">
					<div className="w-full md:w-2/3 bg-blue-500 text-white flex justify-center flex-col rounded shadow-md p-8">
						<h3 className="text-xl mb-3 font-semibold">Company history</h3>
						<p>
							Research is a dificult task to accomplish for even the most experienced scientists. The eleboration of
							hypothesis alone can be challenging and time consuming needing to be pondered at length to assess its
							validity. When adding to this creation of an appropriate testing method which requires precise and full
							proof tooks to reach acceptable results; and the presentaion of experiment to the general public, which
							can be difficult to phrase at the best of times, leads many people to simply abandon the idea of
							conducting research.
						</p>
						<p>
							Our company was founded to try and teach aspiring researchers to convert the seemingly insurmountable
							obstacle into a manageable set of goals, a check-list if you will. There are 100 of companies that provide
							simple services for research. Their aim is to take the work or difficulties from individual and for a
							free, return the problem solved. This may be an effecient way to circumvent a problem but it doesnot solve
							it. Why was this test used? Why was this reference removed? These are the questions one should ask oneself
							so as to do better next time. And these are the questions we intend to answer.
						</p>
					</div>
					<div className="w-full md:w-1/3">
						<div className="bg-gray-50 mb-5 p-8 rounded shadow-md">
							<h3 className="text-xl mb-3 font-semibold text-blue-500">Our Mission</h3>
							<p>
								We aim to allow any individual who is struggling with a practice aspect of research to surmount the
								problem. THis is accomplished by not only providing a service that can tackle it for them but also to
								provide detailed reasons and explainations for each step so that in future, the individual has learnt
								enough to not make the same mistakes twice.
							</p>
						</div>
						<div className="bg-gray-50 p-8 rounded shadow-md">
							<h3 className="text-xl mb-3 font-semibold text-blue-500">Our Vision</h3>
							<p>
								To provide aspiring researchers with the skills and knowledge required to take on any future project
								with confidence.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
