import { Routes, Route } from 'react-router-dom'
import PrivateLayout from './components/Layout/PrivateLayout'
import PublicLayout from './components/Layout/PublicLayout'
import PrivateRoute from './components/PrivateRoute'
import Dashboard from './views/Admin/Dashboard/Dashboard'
import SingleProject from './views/Admin/Projects/SingleProject'
import Settings from './views/Admin/Settings/Settings'
import Login from './views/Auth/Login'
import Home from './views/Home/Home'
import CreateProject from './views/Projects/CreateProject'

function App() {
	return (
		<div className="App">
			<Routes>
				<Route element={<PublicLayout />}>
					<Route path="/" element={<Home />} />
					<Route path="/get-a-quote" element={<CreateProject />} />
					<Route path="/login" element={<Login />} />
				</Route>

				<Route element={<PrivateRoute allowedRoles={['user', 'admin']} />}>
					<Route element={<PrivateLayout />}>
						<Route path="/dashboard" element={<Dashboard />} />
						<Route path="/project/:id" element={<SingleProject />} />
						<Route path="/settings" element={<Settings />} />
					</Route>
				</Route>
			</Routes>
		</div>
	)
}

export default App
