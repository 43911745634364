import React from 'react'
import useAuth from '../../../hooks/useAuth'
import { publicApi } from '../../../helpers/api'
import Input from '../../../components/Form/Input'
import { format } from 'date-fns'
import { Link } from 'react-router-dom'
import Loader from '../../../components/Loaders'
import Badge from '../../../components/Badge'

export default function Dashboard() {
	const { authorization } = useAuth()

	const [projects, setProjects] = React.useState([])
	const [filterProjects, setFilterProjects] = React.useState([])
	const [loading, setLoading] = React.useState(true)

	React.useEffect(() => {
		const auth = new FormData()
		auth.append('authorization', authorization())
		publicApi
			.post('/projects', auth)
			.then(
				({ data }) => {
					setProjects(data)
					setFilterProjects(data)
				},
				err => {
					console.log(err)
				}
			)
			.finally(() => {
				setLoading(false)
			})
	}, [])

	const handleSearch = (e, field) => {
		const { value } = e.target

		const filter = projects.filter(project => {
			return project[field].toString().toLowerCase().includes(value.toLowerCase())
		})
		setFilterProjects(filter)
	}

	const renderStatus = status => {
		if (status === 'pending') {
			return <Badge color="bg-yellow-500">Pending</Badge>
		}
		if (status === 'started') {
			return <Badge color="bg-blue-500">Started</Badge>
		}
		if (status === 'completed') {
			return <Badge color="bg-emerald-500">completed</Badge>
		}
		if (status === 'rejected') {
			return <Badge color="bg-rose-500">Rejected</Badge>
		}
	}

	const renderContactTime = time => {
		if (time === 'morning') {
			return <Badge color="bg-green-500">Morning</Badge>
		}
		if (time === 'afternoon') {
			return <Badge color="bg-blue-500">Afternoon</Badge>
		}
		if (time === 'evening') {
			return <Badge color="bg-orange-500">Evening</Badge>
		}
	}

	return loading ? (
		<Loader />
	) : (
		<div className="mx-auto w-full px-2 sm:px-6 lg:px-8 my-10">
			<h1 className="text-2xl font-bold text-blue-500">Dashboard</h1>
			<p>All projects</p>

			<div className="md:flex gap-5">
				<div className="w-ful md:w-1/6">
					<Input placeholder="Search customer" onChange={e => handleSearch(e, 'customer_name')} />
				</div>
				<div className="w-ful md:w-1/6">
					<Input placeholder="Search refference" onChange={e => handleSearch(e, 'id')} />
				</div>
				<div className="w-ful md:w-1/6">
					<Input placeholder="Search time" onChange={e => handleSearch(e, 'contact_time')} />
				</div>
				<div className="w-ful md:w-1/6">
					<Input placeholder="Search project" onChange={e => handleSearch(e, 'project_name')} />
				</div>
				<div className="w-ful md:w-1/6">
					<Input placeholder="Search service" onChange={e => handleSearch(e, 'project_service')} />
				</div>
				<div className="w-ful md:w-1/6">
					<Input placeholder="Search status" onChange={e => handleSearch(e, 'project_status')} />
				</div>
			</div>
			<div className="overflow-auto lg:overflow-visible w-full">
				<table className="table text-gray-500 border-separate w-full space-y-6 text-sm">
					<thead className="bg-gray-100 text-gray-800 w-100">
						<tr className="w-full">
							<th className="p-3 text-left">CUSTOMER</th>
							<th className="p-3 text-left">REF NO.</th>
							<th className="p-3 text-left">CONTACT TIME</th>
							<th className="p-3 text-left">PROJECT</th>
							<th className="p-3 text-left">SERVICE</th>
							<th className="p-3 text-left">DEADLINE</th>
							<th className="p-3 text-left">RECIEVED @</th>
							<th className="p-3 text-left">STATUS</th>
							<th className="p-3 text-left">ACTION</th>
						</tr>
					</thead>
					<tbody>
						{filterProjects.length > 0 ? (
							filterProjects.map(project => (
								<tr key={project.id} className="bg-gray-100">
									<td className="p-3">
										<div className="">{project.customer_name}</div>
										<a href={`mailto:${project.customer_email}`} className="text-blue-400">
											{project.customer_email}
										</a>
										<div className="text-gray-500">{project.customer_phone}</div>
									</td>
									<td className="p-3">
										<Badge color="bg-blue-500">pub-{project.id}</Badge>
									</td>
									<td className="p-3">{renderContactTime(project.contact_time)}</td>
									<td className="p-3">{project.project_name}</td>
									<td className="p-3 font-bold">{project.project_service}</td>
									<td className="p-3 font-bold">{project.deadline}</td>
									<td className="p-3 font-bold">{format(new Date(project.created_at), 'dd MMM, yyyy hh:ss a')}</td>
									<td className="p-3">{renderStatus(project.project_status)}</td>
									<td className="p-3 whitespace-nowrap">
										<Link to={`/project/${project.id}`} className="text-blue-500 inline hover:text-blue-700 mr-2">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												className="h-6 w-6 inline"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
												strokeWidth={2}
											>
												<path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
												/>
											</svg>
										</Link>
										<a href="/" className="text-rose-500 inline hover:text-rose-700  ml-2">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												className="h-6 w-6 inline"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
												strokeWidth={2}
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
												/>
											</svg>{' '}
										</a>
									</td>
								</tr>
							))
						) : (
							<tr>
								<td colSpan={9}>
									<h3 className="text-center text-2xl text-gray-400 font-semibold my-10">No projects.</h3>
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
		</div>
	)
}
