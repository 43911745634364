import { createContext, useState } from 'react'

const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
	const [auth, setAuth] = useState({})

	function checkAuth() {
		const token = localStorage.getItem('token')
		return token ? true : false
	}

	function getToken() {
		return localStorage.getItem('token')
	}

	function authorization() {
		return 'Bearer ' + getToken()
	}

	function getUser() {
		const user = localStorage.getItem('user')
		return user ? JSON.parse(user) : null
	}

	function logout() {
		localStorage.removeItem('token')
		localStorage.removeItem('user')
		setAuth({})
	}

	return (
		<AuthContext.Provider value={{ auth, setAuth, checkAuth, logout, getUser, getToken, authorization }}>
			{children}
		</AuthContext.Provider>
	)
}

export default AuthContext
