import React from 'react'
import { Link } from 'react-router-dom'
import { services } from '../../../data/services'

export default function Services() {
	return (
		<section className="text-gray-600 body-font" id="services">
			<div className="container px-5 py-24 mx-auto flex flex-wrap">
				<div className="flex flex-wrap w-full">
					<div className="lg:w-2/5 md:w-1/2 md:pr-10 md:py-6">
						<h1 className="text-3xl mb-10 font-medium text-blue-500">Our Services</h1>

						{services.map(service => (
							<div className="flex relative items-center pb-12" key={service.id}>
								<div className="h-full w-10 absolute inset-0 flex items-center justify-center">
									<div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
								</div>
								<div className="flex-shrink-0 w-10 h-10 rounded-full bg-blue-500 inline-flex items-center justify-center text-white relative z-10">
									<svg
										fill="none"
										stroke="currentColor"
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										className="w-5 h-5"
										viewBox="0 0 24 24"
									>
										<path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
									</svg>
								</div>
								<div className="flex-grow pl-4">
									<h2 className="font-medium title-font text-lg text-gray-900 mb-1 tracking-wider">{service.name}</h2>
								</div>
							</div>
						))}
						<div className="w-full my-10">
							<Link
								className="mt-4 mb-3 px-10 bg-blue-500 hover:bg-blue-600 text-white py-2 rounded-md transition duration-100"
								to="/get-a-quote"
							>
								Get a quote now
							</Link>
						</div>
					</div>
					<img
						className="lg:w-3/5 md:w-1/2 object-cover object-center rounded-lg md:mt-0 mt-12"
						src={require('../../../assets/images/services.png')}
						alt="step"
					/>
				</div>
			</div>
		</section>
	)
}
