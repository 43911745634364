import React from 'react'

export default function Loader() {
	return (
		<div className="flex h-[90vh] justify-center items-center">
			<div
				className="spinner-border text-blue-500 animate-spin inline-block w-8 h-8 border-4 rounded-full"
				role="status"
			>
				<span className="visually-hidden">Loading...</span>
			</div>
		</div>
	)
}
