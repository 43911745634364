import React from 'react'

export default function Badge({ color, children }) {
	return (
		<span
			className={`text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap uppercase align-baseline font-bold ${color} text-white rounded-full`}
		>
			{children}
		</span>
	)
}
