import React, { useEffect, useState } from 'react'
import Loader from '../../../components/Loaders'
import { publicApi } from '../../../helpers/api'
import ContactUpdate from './ContactUpdate'

export default function Settings() {
	const [loading, setLoading] = useState(true)
	const [contact, setContact] = useState({
		facebook: '',
		twitter: '',
		instagram: '',
		linkedin: '',
		whatsapp: '',
		email: ''
	})

	useEffect(() => {
		publicApi
			.get('/settings')
			.then(
				({ data }) => {
					setContact(JSON.parse(data.contact_details))
				},
				({ response }) => {
					console.log(response)
				}
			)
			.catch(err => console.log(err))
			.finally(() => setLoading(false))
	}, [])

	return loading ? (
		<Loader />
	) : (
		<div className="mx-auto w-full px-2 sm:px-6 lg:px-8 my-10">
			<h1 className="text-2xl mb-5 font-bold text-blue-500">Contact Details</h1>
			<ContactUpdate contact={contact} setContact={setContact} />
		</div>
	)
}
