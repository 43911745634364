import React, { useEffect } from 'react'
import Input from '../../components/Form/Input'
import Textarea from '../../components/Form/Textarea'
import { services } from '../../data/services'
import { publicApi } from '../../helpers/api'

export default function CreateProject() {
	const [customerName, setCustomerName] = React.useState('')
	const [projectName, setProjectName] = React.useState('')
	const [customerEmail, setCustomerEmail] = React.useState('')
	const [customerPhone, setCustomerPhone] = React.useState('')
	const [contactTime, setContactTime] = React.useState('')
	const [projectService, setProjectService] = React.useState('')
	const [comments, setComments] = React.useState('')
	const [projectFile, setProjectFile] = React.useState('')

	const [customerNameError, setCustomerNameError] = React.useState('')
	const [projectNameError, setProjectNameError] = React.useState('')
	const [customerEmailError, setCustomerEmailError] = React.useState('')
	const [customerPhoneError, setCustomerPhoneError] = React.useState('')
	const [contactTimeError, setContactTimeError] = React.useState('')
	const [projectServiceError, setProjectServiceError] = React.useState('')
	const [commentsError, setCommentsError] = React.useState('')
	const [projectFileError, setProjectFileError] = React.useState('')

	const [success, setSuccess] = React.useState(false)

	const [isLoading, setIsLoading] = React.useState(false)

	useEffect(() => {
		setContactTimeError('')
		setCustomerNameError('')
		setProjectNameError('')
		setCustomerEmailError('')
		setCustomerPhoneError('')
		setCommentsError('')
		setProjectServiceError('')
		setProjectFileError('')
	}, [customerEmail, customerName, customerPhone, contactTime, projectName, projectService, comments])

	const handleSubmit = async e => {
		e.preventDefault()
		setIsLoading(true)

		const data = new FormData()
		data.append('customerName', customerName)
		data.append('projectName', projectName)
		data.append('customerEmail', customerEmail)
		data.append('customerPhone', customerPhone)
		data.append('contactTime', contactTime)
		data.append('projectService', projectService)
		data.append('comments', comments)
		data.append('projectFile', projectFile)

		publicApi
			.post('/projects/create', data)
			.then(
				() => {
					setComments('')
					setCustomerName('')
					setProjectName('')
					setCustomerEmail('')
					setCustomerPhone('')
					setContactTime('')
					setProjectService('')
					setProjectFile(null)
					setIsLoading(false)
					setSuccess(true)
				},
				({ response }) => {
					let errors = response.data.message
					if (errors.customerName) {
						setCustomerNameError(errors.customerName)
					}
					if (errors.projectName) {
						setProjectNameError(errors.projectName)
					}
					if (errors.customerEmail) {
						setCustomerEmailError(errors.customerEmail)
					}
					if (errors.customerPhone) {
						setCustomerPhoneError(errors.customerPhone)
					}
					if (errors.contactTime) {
						setContactTimeError(errors.contactTime)
					}
					if (errors.projectService) {
						setProjectServiceError(errors.projectService)
					}
					if (errors.comments) {
						setCommentsError(errors.comments)
					}
					if (errors.projectFile) {
						setProjectFileError(errors.projectFile)
					}
				}
			)
			.catch(err => {
				console.log(err)
			})
			.finally(() => {
				setIsLoading(false)
			})
	}

	return (
		<div className="max-w-7xl mb-10 mx-auto px-2 sm:px-6 lg:px-8">
			<h1 className="text-3xl text-blue-500 my-10">Get a quote</h1>
			{success && (
				<div className="bg-emerald-100 rounded-lg py-5 px-6 mb-4 text-base text-emerald-700" role="alert">
					Your request has been sent successfully. We will get back to you as soon as possible.
				</div>
			)}
			<form onSubmit={handleSubmit}>
				<div className="w-full">
					<Input
						id="name"
						label={'Your Name *'}
						onChange={({ target }) => setCustomerName(target.value)}
						message={customerNameError}
						value={customerName}
						required
					/>
				</div>
				<div className="flex md:flex-row flex-col gap-5">
					<div className="md:w-1/2 w-full">
						<Input
							id="email"
							label={'Your Email address *'}
							onChange={({ target }) => setCustomerEmail(target.value)}
							type={'email'}
							message={customerEmailError}
							value={customerEmail}
							required
						/>
					</div>
					<div className="md:w-1/2 w-full">
						<Input
							id="phone"
							label={'Your phone number *'}
							onChange={({ target }) => setCustomerPhone(target.value)}
							message={customerPhoneError}
							value={customerPhone}
							required
						/>
					</div>
				</div>
				<div className="w-full">
					<Input
						id="project_name"
						label={'Project Name *'}
						onChange={({ target }) => setProjectName(target.value)}
						message={projectNameError}
						value={projectName}
						required
					/>
				</div>
				<div className="w-full">
					<Textarea
						id="comments"
						value={comments}
						message={commentsError}
						onChange={({ target }) => setComments(target.value)}
						label={'Additional Comments'}
					/>
				</div>
				<div className="flex md:flex-row flex-col my-5 gap-5">
					<div className="w-full md:w-1/2">
						<label className="block font-semibold text-gray-500 text-md mb-2" htmlFor="contactTime">
							Contact Time *
						</label>

						<select
							className="px-4 w-full border-2 py-2 rounded-md text-sm outline-none"
							onChange={({ target }) => setContactTime(target.value)}
							id="contactTime"
						>
							<option value="morning">Morning</option>
							<option value="afternoon">Afternoon</option>
							<option value="evening">Evening</option>
						</select>
						<p className="small text-rose-500">{contactTimeError}</p>
					</div>
					<div className="w-full md:w-1/2">
						<label className="block font-semibold text-gray-500 text-md mb-2" htmlFor="projectService">
							Service Required *
						</label>

						<select
							className="px-4 w-full border-2 py-2 rounded-md text-sm outline-none"
							onChange={({ target }) => setProjectService(target.value)}
							id="projectService"
						>
							{services.map(service => (
								<option key={service.id} value={service.name}>
									{service.name}
								</option>
							))}
						</select>
						<p className="small text-rose-500">{projectServiceError}</p>
					</div>
				</div>
				<div className="w-full">
					<Input
						id="projectFile"
						label={'Project File'}
						onChange={({ target }) => setProjectFile(target.files[0])}
						type={'file'}
						message={projectFileError}
						required
						accept={'.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx'}
					/>
				</div>

				<div className="w-full mt-5">
					<button
						disabled={isLoading}
						className="inline-flex disabled:bg-blue-100 text-white bg-blue-500 border-0 py-2 px-6 focus:outline-none hover:bg-blue-600 rounded text-lg"
					>
						Save
					</button>
				</div>
			</form>
		</div>
	)
}
