import React, { useState } from 'react'
import Input from '../../../components/Form/Input'
import { publicApi } from '../../../helpers/api'
import useAuth from '../../../hooks/useAuth'

export default function ContactUpdate({ contact, setContact }) {
	const { authorization } = useAuth()
	const [message, setMessage] = useState('')

	const handleContactSubmit = () => {
		const auth = new FormData()
		auth.append('authorization', authorization())
		auth.append('contact_details', JSON.stringify(contact))
		publicApi
			.post('/settings/update/contact', auth)
			.then(
				({ data }) => {
					setMessage(data.message)
				},
				({ response }) => {
					console.log(response)
				}
			)
			.catch(err => console.log(err))
			.finally(() =>
				setTimeout(() => {
					setMessage('')
				}, 3000)
			)
	}

	return (
		<div>
			{message && (
				<div className="bg-emerald-100 rounded-lg py-5 px-6 mb-4 text-base text-emerald-700" role="alert">
					{message}
				</div>
			)}
			<div className="flex md:flex-row flex-col gap-5">
				<div className="md:w-1/2 w-full">
					<Input
						type="url"
						label={'Facebook'}
						placeholder={'https://facebook.com/username'}
						value={contact.facebook}
						onChange={e => setContact({ ...contact, facebook: e.target.value })}
					/>
				</div>
				<div className="md:w-1/2 w-full">
					<Input
						type="url"
						label={'Facebook'}
						placeholder={'https://twitter.com/username'}
						value={contact.twitter}
						onChange={e => setContact({ ...contact, twitter: e.target.value })}
					/>
				</div>
			</div>
			<div className="flex md:flex-row flex-col gap-5">
				<div className="md:w-1/2 w-full">
					<Input
						type="url"
						label={'LinkedIn'}
						placeholder={'https://linkedin.com/username'}
						value={contact.linkedin}
						onChange={e => setContact({ ...contact, linkedin: e.target.value })}
					/>
				</div>
				<div className="md:w-1/2 w-full">
					<Input
						type="url"
						label={'Instagram'}
						placeholder={'https://instagram.com/username'}
						value={contact.instagram}
						onChange={e => setContact({ ...contact, instagram: e.target.value })}
					/>
				</div>
			</div>
			<div className="flex md:flex-row flex-col gap-5">
				<div className="md:w-1/2 w-full">
					<Input
						type="number"
						label={'Whatsapp'}
						placeholder={'96693893299'}
						value={contact.whatsapp}
						onChange={e => setContact({ ...contact, whatsapp: e.target.value })}
					/>
				</div>
				<div className="md:w-1/2 w-full">
					<Input
						type="email"
						label={'Email'}
						placeholder={'email@domain.com'}
						value={contact.email}
						onChange={e => setContact({ ...contact, email: e.target.value })}
					/>
				</div>
			</div>
			<button
				onClick={handleContactSubmit}
				className="mt-4 mb-3 w-full bg-blue-500 hover:bg-blue-600 text-white py-2 rounded-md transition duration-100"
			>
				Save
			</button>
		</div>
	)
}
