import React from 'react'
import { Link } from 'react-router-dom'
import useAuth from '../../../hooks/useAuth'
import useOutsideClick from '../../../hooks/useOutsideClick'
import ProfileDropdown from './ProfileDropdown'

export default function PrivateNavbar() {
	const [menuOpen, setMenuOpen] = React.useState(true)

	const { logout, checkAuth } = useAuth()
	const { innerBorderRef: menuRef } = useOutsideClick(() => setMenuOpen(true))

	return (
		<nav className="bg-blue-500">
			<div className="mx-auto px-2 sm:px-6 lg:px-8">
				<div className="relative flex items-center justify-between h-16">
					<div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
						<button
							ref={menuRef}
							type="button"
							className="inline-flex items-center justify-center p-2 rounded-md text-blue-200 hover:text-white hover:bg-blue-600 focus:outline-none"
							aria-controls="mobile-menu"
							aria-expanded="false"
							onClick={() => setMenuOpen(prev => !prev)}
						>
							<span className="sr-only">Open main menu</span>
							<svg
								className={'h-6 w-6 ' + (!menuOpen ? 'hidden' : 'block')}
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								aria-hidden="true"
							>
								<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
							</svg>
							<svg
								className={'h-6 w-6 ' + (!menuOpen ? 'block' : 'hidden')}
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								aria-hidden="true"
							>
								<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
							</svg>
						</button>
					</div>
					<div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-between">
						<div className="flex-shrink-0 flex items-center">
							<h1 className="text-white text-lg font-bold uppercase">Pubhelper</h1>
						</div>
						<div>
							<div className="hidden sm:block sm:ml-6">
								<div className="flex space-x-4">
									<Link
										to="/"
										className="text-gray-100 hover:bg-blue-600 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
									>
										Home
									</Link>
									<Link
										to="/dashboard"
										className="text-gray-100 hover:bg-blue-600 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
									>
										Dashboard
									</Link>
									<Link
										to="/settings"
										className="text-gray-100 hover:bg-blue-600 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
									>
										Settings
									</Link>
								</div>
							</div>
						</div>
						{checkAuth() && <ProfileDropdown logout={logout} />}
					</div>
				</div>
			</div>

			<div className={`sm:hidden ${menuOpen && 'hidden'}`} id="mobile-menu">
				<div className="px-2 pt-2 pb-3 space-y-1">
					<Link
						to="/"
						className="text-gray-100 hover:bg-blue-600 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
					>
						Home
					</Link>
					<Link
						to="/dashboard"
						className="text-gray-100 hover:bg-blue-600 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
					>
						Dashboard
					</Link>
					<Link
						to="/settings"
						className="text-gray-100 hover:bg-blue-600 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
					>
						Settings
					</Link>
				</div>
			</div>
		</nav>
	)
}
