import React from 'react'

export default function Textarea({ label, id, message, ...props }) {
	return (
		<div className="mt-5">
			<label className="block font-semibold text-gray-500 text-md mb-2" htmlFor={id}>
				{label}
			</label>
			<textarea
				id={id}
				{...props}
				rows="5"
				className="px-4 transition-all focus:border-blue-500 w-full border-2 py-2 rounded-md text-sm outline-none"
			/>
			<p className="small text-rose-500">{message}</p>
		</div>
	)
}
