import { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'

import useAuth from '../../hooks/useAuth'
import { publicApi } from '../../helpers/api'
const LOGIN_URL = '/login'

const Login = () => {
	const { checkAuth, setAuth } = useAuth()

	const navigate = useNavigate()
	const location = useLocation()
	const from = location.state?.from?.pathname || '/'

	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [emailError, setEmailError] = useState('')
	const [passwordError, setPasswordError] = useState('')

	useEffect(() => {
		setEmailError('')
		setPasswordError('')
	}, [email, password])

	useEffect(() => {
		if (checkAuth()) {
			navigate(from, { replace: true })
		}
	}, [])

	const handleSubmit = e => {
		e.preventDefault()

		const data = new FormData()
		data.append('email', email)
		data.append('password', password)
		publicApi.post(LOGIN_URL, data).then(
			({ data }) => {
				setAuth({ role: data.user.role, name: data.user.name, id: data.user.id, token: data.token })
				setEmail('')
				setPassword('')
				navigate('/dashboard')
				localStorage.setItem('token', data.token)
				localStorage.setItem('user', JSON.stringify({ role: data.user.role, name: data.user.name, id: data.user.id }))
			},
			({ response }) => {
				let errors = response.data.message
				if (errors.email) {
					setEmailError(errors.email)
				}
				if (errors.password) {
					setPasswordError(errors.password)
				}
			}
		)
	}

	return (
		<div className="min-h-screen bg-blue-500">
			<div className="md:flex justify-end">
				<div className="bg-white min-h-screen md:w-1/3 flex justify-center items-center">
					<div className="md:w-2/3">
						<form onSubmit={handleSubmit}>
							<div>
								<span className="text-sm text-gray-900">Welcome back</span>
								<h1 className="text-2xl font-bold">Login to your account</h1>
							</div>
							<div className="mt-5">
								<label className="block text-md mb-2" htmlFor="email">
									Email
								</label>
								<input
									className="px-4 w-full border-2 py-2 rounded-md text-sm outline-none"
									id="email"
									type="email"
									name="password"
									placeholder="email"
									value={email}
									onChange={e => setEmail(e.target.value)}
								/>
								<p className="small text-rose-500">{emailError}</p>
							</div>
							<div className="my-3">
								<label className="block text-md mb-2" htmlFor="password">
									Password
								</label>
								<input
									className="px-4 w-full border-2 py-2 rounded-md text-sm outline-none"
									id="password"
									type="password"
									name="password"
									placeholder="password"
									onChange={e => setPassword(e.target.value)}
									value={password}
								/>
								<p className="small text-rose-500">{passwordError}</p>
							</div>

							<div className="flex justify-between">
								<Link to="/forgot-password" className="text-sm text-blue-700 hover:underline cursor-pointer">
									Forgot password?
								</Link>
							</div>
							<div className="">
								<button className="mt-4 mb-3 w-full bg-blue-500 hover:bg-blue-600 text-white py-2 rounded-md transition duration-100">
									Login now
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Login
